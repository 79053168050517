import * as React from "react";
import Layout from "../components/layout";
import "../main.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./tabs.scss";
import Accordion from "../components/accordion";

const EducationPage = () => {
  return (
    <Layout>
      <header id="training">Education &amp; Training</header>
      <div class="intro">
        <blockquote>
          All Scripture is God-breathed and is useful for teaching, rebuking,
          correcting and training in righteousness so that the man of God may be
          thoroughly equipped for every good work.
          <cite>–2 Timothy 3:16, 17</cite>
        </blockquote>
        <p>
          If your dispute or conflict is more than you can handle on your own,
          consider our comprehensive dispute resolution and conflict
          reconciliation services. PeacePoint provides coaching, mediation,
          arbitration, and consulting services to individuals, organizations,
          churches, ministries, and denominations. Browse the topics below to
          learn more about our services.
        </p>
        <p>
          Visit{" "}
          <em>
            <strong>Online Opportunities</strong>
          </em>{" "}
          to explore the learning experiences available 24/7. Visit{" "}
          <em>
            <strong>Live Events</strong>
          </em>{" "}
          to learn about the various education and training sessions we offer
          upon request.{" "}
          <a href="/contact" className="link">
            Contact Us
          </a>{" "}
          to schedule or customize a live event for your church, ministry, or
          group. Visit the{" "}
          <em>
            <strong>Resource Library</strong>
          </em>{" "}
          to find interesting articles and other helpful peacemaking resources.
        </p>
      </div>
      <Tabs>
        <TabList>
          <Tab>Online Opportunities</Tab>
          <Tab>Live Events</Tab>
          <Tab>Resource Library</Tab>
        </TabList>

        <TabPanel>
          <p>
            <strong>
              These learning experiences are available to everyone at any time
              and at no charge. All are self-paced, so you can take as much time
              as you need to complete each one. An approximate completion time
              is provided to assist you in planning. Click any title to learn
              more and to get started.
            </strong>
          </p>
          <hr></hr>
          <br></br>
          <h4>Peace with God – (15 minutes)</h4>
          <br></br>
          <p>
            <em>
              Peace with God makes life worth living. Only He can provide
              lasting security and satisfaction. Until you make peace with God,
              you cannot live a full and meaningful life here on earth or live
              forever with Him after death in heaven. He loves you and wants you
              to have all He has promised. Follow this pathway and find genuine
              peace with Him. Don't wait!
            </em>
          </p>
          <br></br>
          <a
            href="https://peace-point.github.io/peace-with-god/"
            target="_blank"
            rel="noreferrer"
            class="main-button button"
          >
            Get Started
          </a>
          <br></br>
          <br></br>
          <br></br>
          <hr></hr>
          <br></br>
          <h4>Finding Peace with Others – (20 minutes)</h4>
          <br></br>
          <p>
            <em>
              Are you involved in a conflict with a family member, a coworker or
              someone else right now? You can expect tension, turmoil, stress
              and suffering until it is resolved. Your broken relationship will
              not get better on its own. God has provided all you need to get
              through that conflict and find peace. Follow this pathway to learn
              how to apply His principles in your situation and pursue peace
              with others!
            </em>
          </p>
          <br></br>
          <a
            href="https://peace-point.github.io/peace-with-others/"
            target="_blank"
            rel="noreferrer"
            class="main-button button"
          >
            Get Started
          </a>
          <br></br>
          <br></br>
          <br></br>
          <hr></hr>
          <br></br>
          <h4>Helping Others Find Peace – (25 minutes)</h4>
          <br></br>
          <p>
            <em>
              Are people around you struggling with conflicts or disputes? Do
              you care about them enough to help? You may be the peacemaker that
              God has placed in their lives to love and encourage them. With
              prayer and some preparation, you can help them pursue
              reconciliation and resolution and find the peace they seek. Follow
              this pathway, and we'll show you how to guide them!
            </em>
          </p>
          <br></br>
          <a
            href="https://peace-point.github.io/helping-others-find-peace/"
            target="_blank"
            rel="noreferrer"
            class="main-button button"
          >
            Get Started
          </a>
          <br></br>
          <br></br>
          <br></br>
          <hr></hr>
          <br></br>
          <h4>Navigating the Peace Map – (25 minutes)</h4>
          <br></br>
          <p>
            <em>
              Are you unsure about what you need to do to get beyond a current
              conflict or dispute and make things right? Those who successfully
              find their way through conflict to peace and unity follow very
              similar pathways to get there. "The Peace Map" will help you see
              and follow those pathways and recognize the biblical milestones
              you will pass as you go. Follow this pathway to explore the Peace
              Map and stay on track!
            </em>
          </p>
          <br></br>
          <a
            href="https://peace-point.github.io/pathway-to-peace/"
            target="_blank"
            rel="noreferrer"
            class="main-button button"
          >
            Get Started
          </a>
          <br></br>
          <br></br>
        </TabPanel>
        <TabPanel>
          <p>
            <strong>
              PeacePoint can provide the following live education and training
              events for your church, ministry, or group. When an open
              enrollment session of the event in which you are interested is
              scheduled, you will be contacted and offered the opportunity to
              attend. Custom events are tailored to meet the specific needs of a
              particular congregation, ministry, or other group and can be
              planned for an evening, a weekend or longer, depending on the
              group’s needs.{" "}
              <a href="/contact" className="link">
                Contact Us
              </a>{" "}
              with any questions you may have or to discuss scheduling a live
              event.
            </strong>
          </p>
          <Accordion
            title="Peacemaking Principles - 4 hours"
            content="<strong>Event Description</strong><br><br>“Blessed are the peacemakers for they shall be called the sons of God” (Matthew 5:9). All Christians are expected to be peacemakers. This 4 hour event acquaints you with basic biblical peacemaking principles and explores how you can apply those principles at home, at work, and in life."
          />
          <Accordion
            title="Conflict Coaching - 1 day"
            content="<strong>Event Description</strong><br><br>“People struggling with conflicts and disputes often need help to get through them. This one day training experience prepares you to use the Peacemaking Principles you know to effectively guide others to reconcile their conflicts and resolve their disputes in a God honoring way. We will give you all the tools you need and then let you practice using them to coach a party in a case study."
          />
          <Accordion
            title="Peace Advocate Prep - 3 days"
            content="<strong>Event Description</strong><br><br>This 3 day training opportunity will acquaint participants with the Biblical truths that are the foundation for Christian Resolution & Reconciliation, and will give them the tools they need to effectively coach others facing conflicts and disputes and to help parties who are willing to meet together in mediation find their way to reconciliation and a God honoring resolution. This event includes:<br><br><ul><li>Pre-Event Online Study Covering Biblical Principles of Christian Resolution & Reconciliation</li><li>Preparing for Outcomes That Glorify God</li><li>Guiding Parties’ Interaction for Resolution & Reconciliation</li><li>Gathering & Using Information to Achieve Resolution & Reconciliation</li><li>Exploring Options for Mutually Acceptable Outcomes</li><li>Overcoming Obstacles to Resolution & Reconciliation</li><li>Ethics and the Law in Christian Resolution & Reconciliation</li><li>Getting to the Heart Issues</li><li>Personal Coaching for Resolution & Reconciliation</li><li>Four Hours of Simulated Mediation Role Play with Feedback</li></ul>"
          />
        </TabPanel>
        <TabPanel>
          <p>
            <strong>
              Our Library contains articles and information on a variety of
              peacemaking topics, as well as links to other helpful websites.
            </strong>
          </p>
          <p>
            <strong>
              Some materials are copyright protected. These are provided with
              permission of the author or owner for the private, personal use of
              our visitors and may not be reproduced or used for any other
              purpose without written permission from the author or owner.
            </strong>
          </p>
          <Accordion
            title="Peace Advocate Training"
            content="
            <a href='https://peace-point.github.io/principles-study/#/' class='link' target='_blank' rel='noopener noreferrer'>The Principles of Christian Resolution & Reconciliation</a>
            <br>
            <br>
            <a href='https://peace-point.github.io/the-processes-of-christian-resolution-and-reconciliation/#/' class='link' target='_blank' rel='noopener noreferrer'>The Processes of Christian Resolution & Reconciliation</a>
            "
          />
          <Accordion
            title="Books and Publications"
            content="
            <a href='https://a.co/d/b1iF9tr' class='link' target='_blank' rel='noopener noreferrer'>PEACEMAKING: A Field Manual</a>
            <br>
            <br>
            <a href='https://www.amazon.com/His-Peace-Your-Life-Promises/dp/0615999972/ref=sr_1_1?s=books&ie=UTF8&qid=1403952222&sr=1-1&keywords=his+peace+for+your+life' class='link' target='_blank' rel='noopener noreferrer'>His Peace For Your Life</a>
            <br>
            <br>
            <a href='https://mega.nz/file/lBMEEQAJ#RbyGb3Dh8HeAKMeusT2cevi6DCO3Ljb937quxm_BUxQ' class='link' target='_blank' rel='noopener noreferrer'>Navigating Congregational Conflict: Achieving Outcomes that Glorify God</a>
            <br>
            <br>
            <a href='https://mega.nz/file/lM0ygIQb#NVe8qVgqaSAwEwwBvkpJ1mFPfjTlRikl-IA-zHQq-5s' class='link' target='_blank' rel='noopener noreferrer'>Enforceability of Religious Arbitration Agreements Awards</a>
            <br>
            <br>
            <a href='https://mega.nz/file/oAE2gK5b#wDRomkTJCc194-fpPYfBU4Fm2MGSbGlliq84UqASenI' class='link' target='_blank' rel='noopener noreferrer'>Peace Points</a>
            <br>
            "
          />
          <Accordion
            title="Peace Point Articles"
            content="
            <a href='https://mega.nz/file/YVciHBzR#zKwavztDz8A3uqu1IRHgmFwHSkhJTddAWaD-3-PQyNc' class='link' target='_blank' rel='noopener noreferrer'>Passages for Peacemakers</a>
            <br>
            <br>
            <a href='https://mega.nz/file/9FFXiJTY#bWjy8Kb_P-3PAElBhgKFr4oAVlRLBGe3EcMOXftDPJA' class='link' target='_blank' rel='noopener noreferrer'>Present Peace</a>
            <br>
            <br>
            <a href='https://mega.nz/file/NcdwWDZB#DZEOK_9wiqsdbzSm9oE66tBNStLemE7rIVKaWZLYzNU' class='link' target='_blank' rel='noopener noreferrer'>Certain Victory</a>
            <br>
            <br>
            <a href='https://mega.nz/file/1cNFjTQI#J-kaHIlqIdJmw1wd_-POVzy1XVuJjv2g9FWZac3b1yk' class='link' target='_blank' rel='noopener noreferrer'>Constant Conflict</a>
            <br>
            <br>
            <a href='https://mega.nz/file/pN8kTJyR#wvoqWlKztDH_Az5qz3zzLdQCZH-zk7DyozYm5d6V2V8' class='link' target='_blank' rel='noopener noreferrer'>God’s Plan A</a>
            <br>
            <br>
            <a href='https://mega.nz/file/xBVQWADY#yBXKe7FScoAT3dAfUkyvWqrvXRIRiup4zrsxA225dQk' class='link' target='_blank' rel='noopener noreferrer'>Great Expectations</a>
            <br>
            <br>
            <a href='https://mega.nz/file/oM9gkbKA#--4YjD6cfbptwjBffPxcCFWg__dqkHcWVMeOAIRt6IE' class='link' target='_blank' rel='noopener noreferrer'>Of Jesus and Duct Tape</a>
            <br>
            <br>
            <a href='https://mega.nz/file/4MlQABrC#bEX9XrNc_TpppYQeSYqDm2SZh0nxUJ9TpqilJ2TUY-o' class='link' target='_blank' rel='noopener noreferrer'>REthinking</a>
            <br>
            "
          />
          <Accordion
            title="Links"
            content="
            <a href='https://www.aorhope.org/' class='link' target='_blank' rel='noopener noreferrer'>Ambassadors of Reconciliation</a>
            <br>
            <br>
            <a href='https://rw360.org' class='link' target='_blank' rel='noopener noreferrer'>Relational Wisdom 360</a>
            <br>
            "
          />
          <Accordion
            title="Audio Resources"
            content="
            <a href='https://mega.nz/file/9Rsz3RwT#2yg58H28dkswgK0rprtuEM2_SE4UtkknWeG9h0nBmJc' class='link' target='_blank' rel='noopener noreferrer'>Navigating Conflict with Church Leaders (40 Min)</a>
            <br>
            <br>
            <a href='https://mega.nz/file/sQFlxBKC#ct0EfdXj1blSUwzOQIjnJHHHWbFJuZJPFmVX0dLyPzY' class='link' target='_blank' rel='noopener noreferrer'>Christians in the Courts – What does God expect? (40 Min)</a>
            <br>
            <br>
            <a href='https://mega.nz/file/ZIlUARiS#CiDHjbJP5nzuz9FJ21iK6YybJdcOT10eChry4N0ab2g' class='link' target='_blank' rel='noopener noreferrer'>Confrontation: Loving Others with the Truth (40 Min)</a>
            <br>
            <br>
            <a href='https://mega.nz/file/8Z8wwCKa#wbHYLC_NZHzlV9kalUBD2zV0l2ssDEkoup18ADMgJTw' class='link' target='_blank' rel='noopener noreferrer'>When Conflict Hits Home (40 Min)</a>
            <br>
            <br>
            <a href='https://mega.nz/file/hNEC0ILZ#MtFe6ZdRXPbgQNE_p4XWhxb8mXbiTYRNmLMo0y9oDkk' class='link' target='_blank' rel='noopener noreferrer'>Conflict: God Has a Plan (40 Min)</a>
            <br>
            "
          />
          <Accordion
            title="Videos"
            content="
            <a href='https://vimeo.com/39939091' class='link' target='_blank' rel='noopener noreferrer'>Endless Grace: A Story of Forgiveness</a>
            <br>
            "
          />
        </TabPanel>
      </Tabs>
    </Layout>
  );
};

export default EducationPage;
